<template>
    <div class="detailView_container">
        <div class="deteailView_contentContainer" v-for="(currentContent, index) in myInfo.contentList" :key="index">
            <div class="detailView_imageContainer" v-if="currentContent.type === 'imageElement'">
                <img :src="getImageURL(currentContent.content)" class="detailView_image">
                <div class="detailView_imageDescription">{{ currentContent.description }}</div>
            </div>
            <div v-else-if="currentContent.type === 'iFrameVideoElement'">
                <div class="detailView_iFrameVideoContainer" v-html="currentContent.content"></div>
                <div class="detailView_iFrameVideoDescription">{{ currentContent.description }}</div>
            </div>
            <div class="detailView_htmlContainer" v-else-if="currentContent.type === 'htmlElement'"  v-html="currentContent.content">
            </div>
            <div class="detailView_textContainer" v-else-if="currentContent.type === 'textElement'">{{currentContent.content}}
            </div>
        </div>
    </div>
</template>

<script>
    import('../css/detailView.css')
    export default {
        name: 'detailView',
        props: {
            myInfo: Object
        },
        created(){
            console.info(this.myInfo)
        },
        methods: {
            getImageURL(imagePath) {
                return require("@/assets/" + imagePath)
            }
        }
    }
</script>
