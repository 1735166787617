<template>
    <div class="container">
        <img :src="getImageURL(myInfo.image)" class="interestImage">
        <div class="interestContainer">
            <div class="interestTitle">{{ myInfo.title }}</div>
            <div class="interestContent">{{ myInfo.content }}</div>
            <div class="interestDetailButton"  @click.prevent="showInterestPanel()">{{ detailText }}</div>
        </div>
    </div>
</template>

<script>
    import('../css/interestInfo.css')
    export default {
        name: 'interestInfo',
        props: {
            myInfo: Object,
            detailText: String
        },
        methods: {
            getImageURL(imagePath) {
                return require("@/assets/" + imagePath)
            },
            showInterestPanel(){
                this.$emit('showInterestPanel', this.myInfo)
            },
        },
    }
</script>
