<template>
    <div class="popupPanel_outterFrame">
        <div class="popupPanel_innerFrame">
            <div class="popupPanel_titleFrame">
                <div class="popupPanel_title">{{ title }}</div>
                <div class="fa-regular fa-xmark popupPanel_closeIcon" @click="closePanl"></div>
            </div>
            
            <div :class="hasFooter == true? 'popupPanel_contentFrame' : 'popupPanel_contentFrame_noFooter'">
                <detailView v-if="projectDetail.detailContent != null" :myInfo="projectDetail.detailContent"></detailView>
            </div>
            <div v-if="hasFooter == true" class="popupPanel_footerFrame">

            </div>
        </div>
    </div>
</template>
<script>
    import('../css/popupPanel.css')
    import detailView from './detailView.vue'
    export default {
        name: 'popupPanel',
        props: {
            hasFooter: Boolean,
            title: String,
            projectDetail: Object
        },
        components: {
            detailView
        },
        created(){
            console.info(this.projectDetail)
        },
        methods: {
            closePanl(){
                this.$emit('closePanl')
            }
        }
    }
</script>