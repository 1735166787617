<template>
    <div class="tagFilter_container">
        <div v-for="(curentTag) in tagList" :key="curentTag.key" @click="onTagSelected(curentTag)" :class="curentTag.isSelected == true? 'tagFilter_tag tagFilter_selected' : 'tagFilter_tag tagFilter_notSelected'">{{ curentTag.name }}</div>
    </div>
</template>
<script>
import('../css/tagFilter.css')
    export default {
        name: 'tagFilter',
        props: {
            tagList: Object
        },
        methods: {
            onTagSelected(selctedTag){
                this.$emit('onTagSelected', selctedTag);
            }
        }
    }
</script>