<template>
    <div :class=" myIndex==maxIndex ? 'homePage-eduInfoItem' : 'homePage-eduInfoItem homePage-infoItem-bottomBorder'">
        <img class="homePage-eduInfoLogo" :src="getImageURL(myInfo.logo)">
        <div class="homePage-eduInfoTitle">{{myInfo.name}}</div>
        <div class="homePage-eduInfoDuration">{{myInfo.duration}}</div>
        <div class="homePage-eduInfoDetail">
            <div v-for="(detail, index) in myInfo.detail" :key="index">
                <div class="homePage-eduInfoDetail-Item">
                    <div class="homePage-eduInfoDetail-Content">{{ detail.content }}</div>
                    <div class="homePage-eduInfoDetail-Description">{{ detail.description }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'eduInfo',
        props: {
            myInfo: Object,
            myIndex: Number,
            maxIndex: Number
        },
        methods: {
            getImageURL(imagePath) {
                return require("@/assets/" + imagePath)
            }
        },
    }
</script>
