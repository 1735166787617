export default {
    data() {
      return {
        tw: {
            introInfo: {
                name: "黃慕凱",
                subName: "MU-KAI HUANG",
                basicIntro: "軟體與全端工程師，主要專注於系統架構與全端設計開發，有11年系統開發與4年團隊管理經驗。",
                locationIntro: "現居住於台灣台南，尋找遠距或台南的工作機會。",
                slideToView: "往下滑動觀看我的履歷",
                downloadButtonList: [
                    {text: '下載履歷表', fileName: 'MuKaiHuang_CV_Chinese.pdf', downloadable: true},
                    {text: '下載履歷簡報', fileName: 'MuKaiHuang_Intro_Slide_Chinese.pdf', downloadable: true},
                    {text: '下載作品集', fileName: 'MuKaiHuang_Portfolio_Chinese.pdf', downloadable: true},
                    {text: '查看多益成績', fileName: 'MuKaiHuang_Toeic_Score.pdf', downloadable: true},
                ]
            },
            sectionTitleInfo: {
                personalData: "個人資料",
                basic: "基本資料",
                work: "工作經歷",
                education: "學歷",
                professional: "專業能力",
                skills: "開發技能",
                projects: "執行專案",
                design: "我的設計",
                interest: "個性與興趣",
                publish: "著作與認證",
                contect: "聯絡方式",
                preferLocation: "偏好工作地點"
            },
            subSectionTitleInfo: {
                personality: "個性",
                interest: "興趣",
                tel: "電話:",
                mail: "E-Mail:",
                viewMore: "點擊查看更多",
                detail: "詳細"
            },
            sectionDescription: {
                project: "工作期間，我所參與的專案類型包含智慧製造、雲端技術、運動科技、AR應用、管理工具...等。",
                design: "除了開發工作之外，鑑於我對設計與美學的喜好，我亦主動協助設計與統籌部門需要美術象意呈現的工作。",
                personality_1: "我是一個樂於嚐鮮，接觸新技術與新產品，尤其是尋求改善技術或工具讓作業更便利，像研究智慧家庭，透過原生智慧家電與跨廠牌整合，將科技融入生活，提升生活品質。",
                personality_2: "同時，我也是個樂於分享與助人的人，工作上樂於替部門建立共用環境，工作之外也經常響應公益活動，像是淨灘、回收、贈物。",
                personality_3: "另一方面，我也喜歡接觸設計美學，從平面設計到室內軟裝擺設，都極具興趣。而除了靜態的休閒，我也喜歡路跑與健身等動態活動，並保持一年參加一路跑賽，以及一週四練的規律運動習慣。",
                phone: "(+886) 921-740-730",
                mail: "mantos730@gmail.com",
                location: "Tainan, Taiwan",
                remote: "遠端工作",
                copyright: "Copyrights &copy; 2023 All Rights Reserved by Mu-Kai Huang."
            },
            menuInfo: {
                menuList: [
                    {name: "首頁", icon: "icon-line2-home", dataHref: "#home"},
                    {name: "基本資料", icon: "icon-line2-user", dataHref: "#basicInfo"},
                    {name: "專業能力", icon: "icon-line2-briefcase", dataHref: "#professional"},
                    {name: "開發技能", icon: "icon-line2-wrench", dataHref: "#skills"},
                    {name: "執行專案", icon: "icon-line2-grid", dataHref: "#projects"},
                    {name: "設計", icon: "icon-line2-magic-wand", dataHref: "#design"},
                    {name: "個性與興趣", icon: "icon-line2-puzzle", dataHref: "#interest"},
                    {name: "著作與認證", icon: "icon-line2-book-open", dataHref: "#articles"},
                    {name: "聯絡資訊", icon: "icon-line2-speech", dataHref: "#contact"}
                ]
            },
            basicInfo: [
                {icon: "", title: "姓名", content: "黃慕凱"},
                {icon: "", title: "性別", content: "男性"},
                {icon: "", title: "生日", content: "1985.07.30"},
                {icon: "", title: "E-Mail", content: "mantos730@gmail.com"},
                {icon: "", title: "電話", content: "+886-921-740-730"},
                {icon: "", title: "國籍", content: "台灣"},
                {icon: "", title: "母語", content: "中文、台語"},
                {icon: "", title: "第二外語", content: "英文 (TOEIC:905)"}
            ],
            careerInfo: [
                {logo: "logo/delta.png", name: "台達電子", duration: "2023 -", 
                    detail: [
                        {content: "資深課長, 2023 - ", description: "製造管理開發部"}
                    ]
                },
                  {logo: "logo/itri.png", name: "工業技術研究院", duration: "2012 - 2023", 
                      detail: [
                          {content: "技術副理, 2021 - 2023", description: "數位轉型技術組"},
                          {content: "Team Leader, 2019 - 2023", description: "數位轉型技術組"},
                          {content: "資深工程師, 2021 - 2023", description: "數位轉型技術組"},
                          {content: "工程師, 2018 - 2021", description: "智慧製造服務系統組"},
                          {content: "副工程師, 2015 - 2018", description: "智慧製造服務系統組"},
                          {content: "Outsourcing, 2012 - 2015", description: "雲端服務中心"}
                      ]
                  }
            ],
            educationInfo: [
                {logo: "logo/ntust.png", name: "國立台灣科技大學", duration: "2005 - 2011",
                    detail: [
                        {content: "碩士, 2007 - 2011", description: "電子工程所"},
                        {content: "博士侯選人, 2008 - 2011 (肄業)", description: "電子工程所"},
                        {content: "學士, 2005 - 2007", description: "電子工程系"}
                    ]
                },
                {logo: "logo/isu.png", name: "Iowa State University, USA", duration: "2009 - 2011",
                    detail: [
                        {content: "交換學生, 2009 - 2011", description: "Computer Science"}
                    ]
                }
            ]
        },
        en: {
            introInfo: {
                name: "MU-KAI HUANG",
                subName: "Chris",
                basicIntro: "A software and full stack engineer, interests in system architecture, UI/UX design and full stack development. With 11 years developer and 4 year management experiences.",
                locationIntro: "Currently live in Tainan, Taiwan. Looking for opertunities to work in Tainan or remote work.",
                slideToView: "Slide Down to Show More",
                downloadButtonList: [
                    //{text: '下載履歷表', fileName: 'MuKaiHuang_CV_Chinese.pdf', downloadable: true},
                    //{text: '下載履歷簡報', fileName: 'MuKaiHuang_Intro_Slide_Chinese.pdf', downloadable: true},
                    //{text: '下載作品集', fileName: 'MuKaiHuang_Portfolio_Chinese.pdf', downloadable: true},
                ]
            },
            sectionTitleInfo: {
                personalData: "personal Information",
                basic: "Basic",
                work: "Work Experiences",
                education: "Education",
                professional: "Professional",
                skills: "Skills",
                projects: "Projects",
                design: "My Design",
                interest: "Personality and Interest",
                publish: "Publication and Certification",
                contect: "Contact Me",
                preferLocation: "Prefer Location"
            },
            subSectionTitleInfo: {
                personality: "Personality",
                interest: "Interest",
                tel: "Mobile:",
                mail: "E-Mail:",
                viewMore: "Click for More",
                detail: "Detail"
            },
            sectionDescription: {
                project: "During my work, the types of projects I have been involved in include smart manufacturing, cloud technology, sports technology, AR applications, management tools, and more.",
                design: "In addition to development work, given my preference for design and aesthetics, I also proactively assist the design and coordination department with work that requires artistic expression.",
                personality_1: "I enjoy trying new things and exploring new technologies and products, especially seeking to improve technology or tools to make work more convenient. For example, researching smart homes, integrating technology into life through native smart appliances and cross-brand integration, and improving the quality of life.",
                personality_2: "I am also someone who enjoys sharing and helping others. At work, I am happy to establish a shared environment for the department, and outside of work, I often respond to public welfare activities, such as beach cleaning, recycling, and charity giving.",
                personality_3: "On the other hand, I also like to explore design aesthetics, from graphic design to interior soft furnishings, which are of great interest to me. In addition to static leisure, I also enjoy dynamic activities such as running and fitness, and maintain a regular exercise habit of participating in a marathon once a year and training four times a week.",
                phone: "(+886) 921-740-730",
                mail: "mantos730@gmail.com",
                location: "Tainan, Taiwan",
                remote: "Remote Work",
                copyright: "Copyrights &copy; 2023 All Rights Reserved by Mu-Kai Huang."
            },
            menuInfo: {
                menuList: [
                    {name: "Home", icon: "icon-line2-home", dataHref: "#home"},
                    {name: "Basic", icon: "icon-line2-user", dataHref: "#basicInfo"},
                    {name: "Professional", icon: "icon-line2-briefcase", dataHref: "#professional"},
                    {name: "Skills", icon: "icon-line2-wrench", dataHref: "#skills"},
                    {name: "Projects", icon: "icon-line2-grid", dataHref: "#projects"},
                    {name: "My Design", icon: "icon-line2-magic-wand", dataHref: "#design"},
                    {name: "Personality", icon: "icon-line2-puzzle", dataHref: "#interest"},
                    {name: "Publication", icon: "icon-line2-book-open", dataHref: "#articles"},
                    {name: "Contact", icon: "icon-line2-speech", dataHref: "#contact"}
                ]
            },
            basicInfo: [
                {icon: "", title: "Name", content: "Mu-Kai Huang"},
                {icon: "", title: "Gender", content: "Male"},
                {icon: "", title: "Birthday", content: "1985.07.30"},
                {icon: "", title: "E-Mail", content: "mantos730@gmail.com"},
                {icon: "", title: "Tel", content: "+886-921-740-730"},
                {icon: "", title: "From", content: "Taiwan"},
                {icon: "", title: "Lang", content: "Chinese, Taiwanese"},
                {icon: "", title: "2nd Lang", content: "English (TOEIC:905)"}
            ],
            careerInfo: [
                {logo: "logo/delta.png", name: "Delta Electronics Inc.", duration: "2023 -", 
                    detail: [
                        {content: "Senior Section Chief, 2023 - ", description: "FAS"}
                    ]
                },
                  {logo: "logo/itri.png", name: "Industrial Technology Research Institute", duration: "2012 - 2023", 
                      detail: [
                          {content: "Deputy Technical Manager, 2021 - 2023", description: "IST"},
                          {content: "Team Leader, 2019 - 2023", description: "IST"},
                          {content: "Sr. Engineer, 2021 - 2023", description: "IST"},
                          {content: "Engineer, 2018 - 2021", description: "ICL"},
                          {content: "Jr. Engineer, 2015 - 2018", description: "ICL"},
                          {content: "Outsourcing, 2012 - 2015", description: "IST"}
                      ]
                  }
            ],
            educationInfo: [
                {logo: "logo/ntust.png", name: "National Taiwan University of Science and Technology", duration: "2005 - 2011",
                    detail: [
                        {content: "Master, 2007 - 2011", description: "Electronic Engineering"},
                        {content: "Ph. D. Candidates, 2008 - 2011", description: "Electronic Engineering"},
                        {content: "Bachelor, 2005 - 2007", description: "Electronic Engineering"}
                    ]
                },
                {logo: "logo/isu.png", name: "Iowa State University, USA", duration: "2009 - 2011",
                    detail: [
                        {content: "Exchange Scholar, 2009 - 2011", description: "Computer Science"}
                    ]
                }
            ]
        }
        
      };
    }
  };