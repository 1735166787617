<template>
    <div class="container">
        <div class="publishContainer">
            <div class="publishTitle">{{ myInfo.title }}</div>
            <div class="publishContent" v-html="myInfo.contentHTML"></div>
        </div>
    </div>
</template>

<script>
    import('../css/publishInfo.css')
    export default {
        name: 'publishInfo',
        props: {
            myInfo: Object
        },
        methods: {

        },
    }
</script>
