export default {
    data() {
      return {
        tw: {
          myPublishInfo: [
              {
                  title: '論文著作',
                  contentHTML: '<ul style="margin-left:40px"><li style="text-align:justify">黃慕凱、陳昌憲、陳威仲，&quot;製造業的淨零碳排數位轉型路徑&quot;，機械工業雜誌雷射應用與數位轉型技術專輯，2023。</li><li style="text-align:justify">T. -S. Cheng, K. -M. Lee, Y. -H. Lu, M. -K. Huang and W. -T. Hong, &quot;Design and Implement for Reducing the Temporary High Load of Device in Industrial Networks,&quot;&nbsp;<em>2018 International Conference on System Science and Engineering (ICSSE)</em>, New Taipei, Taiwan, 2018, pp. 1-4.</li><li style="text-align:justify">黃慕凱、黃柏錡、陳蘊彥、吳金能，&quot;泛用型可配置式雲端視覺化監控系統&quot;，電腦與通訊第172期Cloud技術專輯，2017，pp. 46-49。</li><li style="text-align:justify">Kuen-Min Lee, Wei-Guang Teng, Jin-Neng Wu, Ping-Yu Chen, Mu-Kai Huang, Ting-Wei Hou, &quot;Dynamic distribution technique for session management in CWMP server clusters&quot;, Electronics Letters (IET Electronics Letters), Volume50, Issue22, 2014, pp. 1639-1641.</li><li style="text-align:justify">Wu, Jin-Neng &amp; Ko, Yao-Hsing &amp; Huang, K. C. &amp; Huang, Mu-Kai. &quot;Heterogeneous Diskless Remote Booting System on Cloud Operating System.&quot;, Lecture Notes in Computer Science (LNTCS,volume 7861). pp. 114-123.&nbsp;</li><li style="text-align:justify">Huang, Mu-Kai, J. Morris Chang, and Wei-Mei Chen. &quot;Grouping-based dynamic power management for multi-threaded programs in chip-multiprocessors.&quot;&nbsp;<em>2009 International Conference on Computational Science and Engineering</em>. Vol. 2. IEEE, 2009.</li><li style="text-align:justify">W. -M. Chen and M. -K. Huang, &quot;Efficient Perfectly Periodic Scheduling for Data Broadcasting,&quot;&nbsp;<em>2008 International Symposium on Ubiquitous Multimedia Computing</em>, Hobart, TAS, Australia, 2008, pp. 29-34.</li></ul>               '
              },
              {
                  title: '專利',
                  contentHTML: '<ul style="margin-left:40px"><li style="text-align:justify">伺服器及其資源調控方法,&nbsp;TW202016751A (台灣)，2020-05-01。</li><li style="text-align:justify">交談式遠端管理系統及其負載平衡控制方法，TWI568213B (台灣)，2017-01-21。</li><li style="text-align:justify">Session-based remote management system and load balance controlling method，US9535775B2 (US)，2017-01-03。</li><li style="text-align:justify">Server and resource adjustment control method，US20200127935A1 (US)，2020-04-23 (Abandoned)。</li></ul>               '
              },
              {
                  title: '認證',
                  contentHTML: '<ul style="margin-left:40px"><li style="text-align:justify">SGS ISO 14064-1:2018 溫室氣體盤查標準主導查證員訓練課程</li><li style="text-align:justify">SGS 鐵路應用 EN50126、EN50129、EN50128、EN50159培訓課程</li><li style="text-align:justify">工研院功能主管及計畫主持人卡內基訓練</li></ul>                '
              }
          ]
        },
        en: {
          myPublishInfo: [
              {
                title: 'Publications',
                contentHTML: '<ul style="margin-left:40px"><li style="text-align:justify">黃慕凱、陳昌憲、陳威仲，&quot;製造業的淨零碳排數位轉型路徑&quot;，機械工業雜誌雷射應用與數位轉型技術專輯，2023。</li><li style="text-align:justify">T. -S. Cheng, K. -M. Lee, Y. -H. Lu, M. -K. Huang and W. -T. Hong, &quot;Design and Implement for Reducing the Temporary High Load of Device in Industrial Networks,&quot;&nbsp;<em>2018 International Conference on System Science and Engineering (ICSSE)</em>, New Taipei, Taiwan, 2018, pp. 1-4.</li><li style="text-align:justify">黃慕凱、黃柏錡、陳蘊彥、吳金能，&quot;泛用型可配置式雲端視覺化監控系統&quot;，電腦與通訊第172期Cloud技術專輯，2017，pp. 46-49。</li><li style="text-align:justify">Kuen-Min Lee, Wei-Guang Teng, Jin-Neng Wu, Ping-Yu Chen, Mu-Kai Huang, Ting-Wei Hou, &quot;Dynamic distribution technique for session management in CWMP server clusters&quot;, Electronics Letters (IET Electronics Letters), Volume50, Issue22, 2014, pp. 1639-1641.</li><li style="text-align:justify">Wu, Jin-Neng &amp; Ko, Yao-Hsing &amp; Huang, K. C. &amp; Huang, Mu-Kai. &quot;Heterogeneous Diskless Remote Booting System on Cloud Operating System.&quot;, Lecture Notes in Computer Science (LNTCS,volume 7861). pp. 114-123.&nbsp;</li><li style="text-align:justify">Huang, Mu-Kai, J. Morris Chang, and Wei-Mei Chen. &quot;Grouping-based dynamic power management for multi-threaded programs in chip-multiprocessors.&quot;&nbsp;<em>2009 International Conference on Computational Science and Engineering</em>. Vol. 2. IEEE, 2009.</li><li style="text-align:justify">W. -M. Chen and M. -K. Huang, &quot;Efficient Perfectly Periodic Scheduling for Data Broadcasting,&quot;&nbsp;<em>2008 International Symposium on Ubiquitous Multimedia Computing</em>, Hobart, TAS, Australia, 2008, pp. 29-34.</li></ul>               '
              },
              {
                title: 'Patents',
                contentHTML: '<ul style="margin-left:40px"><li style="text-align:justify">伺服器及其資源調控方法,&nbsp;TW202016751A (Taiwan)，2020-05-01。</li><li style="text-align:justify">交談式遠端管理系統及其負載平衡控制方法，TWI568213B (Taiwan)，2017-01-21。</li><li style="text-align:justify">Session-based remote management system and load balance controlling method，US9535775B2 (US)，2017-01-03。</li><li style="text-align:justify">Server and resource adjustment control method，US20200127935A1 (US)，2020-04-23 (Abandoned)。</li></ul>               '
              },
              {
                  title: 'Certifications',
                  contentHTML: '<ul style="margin-left:40px"><li style="text-align:justify">SGS ISO 14064-1:2018 Greenhouse Gas Inventory Standard Leading Verifier Training Course</li><li style="text-align:justify">SGS EN50126、EN50129、EN50128、EN50159 Railway Application Training Course</li><li style="text-align:justify">Carnegie Training for ITRI Functional Managers and Project Leaders</li></ul>                '
              }
          ]
        }
      }
    }
}