<template>
    <!--<transition>-->
        <div class="col-sm-6 col-lg-4"  v-if="myInfo.isShown">
            <div class="feature-box fbox-plain">
                <div class="fbox-icon">
                    <a><i :class="myInfo.icon" style="color: #DDD"></i></a>
                </div>
                <div class="fbox-content">
                    <h3 class="font-weight-normal ls2" style="color: #FFF">{{myInfo.name}}</h3>
                    <p style="color:#AAA;">{{myInfo.description}}</p>
                </div>
            </div>
        </div>
    <!--</transition>-->
</template>

<script>
    export default {
        name: 'skillInfo',
        props: {
            myInfo: Object,
            myIndex: Number,
            maxIndex: Number
        },
        methods: {

        },
    }
</script>
