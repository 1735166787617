<template>
    <div class="homePage-basicInfoItem">
        <div class="homePage-basicInfoIcon"></div>
        <div class="homePage-basicInfoTitle">{{myInfo.title}}</div>
        <div class="homePage-basicInfoContent">{{ myInfo.content }}</div>
    </div>
</template>

<script>
    export default {
        name: 'basicInfo',
        props: {
            myInfo: Object
        }
    }
</script>
