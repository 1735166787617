<template>
    <!--<transition>-->
        <div class="entry col-md-6 col-lg-4 projectInfo_container" v-if="myInfo.isShown"  >
            <div class="grid-inner">
                <div class="entry-box-shadow">
                    <div class="entry-image mb-0">
                        <a href="" @click.prevent="showProjectPanel()" data-lightbox="image"><img :src="getImageURL(myInfo.image)" :alt="myInfo.alt"></a>
                    </div>
                    <div class="entry-meta-wrapper">
                        <div class="entry-title">
                            <h2><a href="" @click.prevent="showProjectPanel()">{{ myInfo.title }}</a></h2>
                        </div>
                        <div class="entry-meta mt-0">
                            <a class="text-muted"> {{ myInfo.subTitle }}</a>
                        </div>
                        <div class="entry-content mt-3 projectInfo_content">
                            <p class="mb-0">{{ myInfo.description }}</p>
                        </div>
                        <div class="projectInfo_viewDetail">
                            <a href="" @click.prevent="showProjectPanel()">{{ this.viewMoreText }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!--</transition>-->
</template>

<script>
    import('../css/projectInfo.css')
    export default {
        name: 'projectInfo',
        props: {
            myInfo: Object,
            myIndex: Number,
            maxIndex: Number,
            viewMoreText: String
        },
        methods: {
            getImageURL(imagePath) {
                return require("@/assets/" + imagePath)
            },
            showProjectPanel(){
                this.$emit('showProjectPanel', this.myInfo)
            },
        }
    }
</script>
