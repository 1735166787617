export default {
    data() {
      return {
        tw: {
            skillTags: [
                {key: 'all', name: "全部", isSelected: true},
                {key: 'frontend', name: "前端開發", isSelected: false},
                {key: 'backend', name: "後端開發", isSelected: false},
                {key: 'application', name: "應用程式開發", isSelected: false},
                {key: 'cli', name: "CLI", isSelected: false},
                {key: 'uiux', name: "UI/UX", isSelected: false},
                {key: 'design', name: "設計", isSelected: false},
                {key: 'cicd', name: "CI/CD", isSelected: false},
                {key: 'database', name: "資料庫", isSelected: false},
                {key: 'protocol', name: "通訊協定", isSelected: false}
            ],
            skillInfo: [
                {
                    name: "Javascript",
                    icon: "fa-brands fa-square-js",
                    description: "熟悉JS技術，並成功開發N個專案系統前端服務與3個自製系統框架。",
                    tag: ['frontend'],
                    isShown: true
                },
                {
                    name: "HTML5",
                    icon: "fa-brands fa-html5",
                    description: "建構前端系統網頁Layout，並關注每年前端頁面設計與開發趨勢。",
                    tag: ['frontend'],
                    isShown: true
                },
                {
                    name: "React",
                    icon: "fa-brands fa-react",
                    description: "使用React框架，成功打造部門用人事資源管理與分配工具，計算分析部門每月成本與KPI與盈餘狀況。",
                    tag: ['frontend'],
                    isShown: true
                },
                {
                    name: "Angular",
                    icon: "fa-brands fa-angular",
                    description: "使用Angular框架，打造日本電信大廠使用的雲端VM租用服務網。",
                    tag: ['frontend'],
                    isShown: true
                },
                {
                    name: "Vue",
                    icon: "fa-brands fa-vuejs",
                    description: "使用Vue框架，打造個人履歷網站。",
                    tag: ['frontend'],
                    isShown: true
                },
                {
                    name: "jQuery",
                    icon: "fa-solid fa-q",
                    description: "以jQuery Library進行DOM元件操作。",
                    tag: ['frontend'],
                    isShown: true
                },
                {
                    name: "Typescript",
                    icon: "fa-sharp fa-solid fa-t",
                    description: "使用TS語法，開發日本電信大廠使用的雲端VM租用服務。",
                    tag: ['frontend'],
                    isShown: true
                },
                {
                    name: "CSS",
                    icon: "fa-brands fa-css3-alt",
                    description: "透過CSS語法，開發UI樣式與動畫。",
                    tag: ['frontend'],
                    isShown: true
                },
                {
                    name: "Java",
                    icon: "fa-brands fa-java",
                    description: "開發後端之API Handler、Manager、Controller，並進行資料庫存取。",
                    tag: ['backend', 'application'],
                    isShown: true
                },
                {
                    name: "Node.js",
                    icon: "fa-brands fa-node-js",
                    description: "開發後端之API並進行資料庫存取",
                    tag: ['backend'],
                    isShown: true
                },
                {
                    name: "JavaFX",
                    icon: "fa-brands fa-java",
                    description: "以JAVA開發桌面化程式。",
                    tag: ['application'],
                    isShown: true
                },
                {
                    name: "GO",
                    icon: "fa-brands fa-golang",
                    description: "開發後端之API並進行跨系統Rest API呼叫。",
                    tag: ['backend'],
                    isShown: true
                },
                {
                    name: "Android JAVA",
                    icon: "fa-brands fa-android",
                    description: "開發Android APP及Android Wear APP。",
                    tag: ['application'],
                    isShown: true
                },
                {
                    name: "iOS Swift",
                    icon: "fa-brands fa-swift",
                    description: "開發 iPhone及iPad APP",
                    tag: ['application'],
                    isShown: true
                },
                {
                    name: "Axure RP",
                    icon: "fa-solid fa-compass-drafting",
                    description: "進行UI Mockup、UX 操作設計與UI Prototype文件輸出。",
                    tag: ['uiux', 'design'],
                    isShown: true
                },
                {
                    name: "C",
                    icon: "fa-solid fa-c",
                    description: "修改Linux OS核心，開發Scheduling演算法，根據Process，預測其CPU、Memory行為，進行CPU調頻減少能耗。",
                    tag: ['cli'],
                    isShown: true
                },
                {
                    name: "bash script",
                    icon: "fa-solid fa-terminal",
                    description: "開發系統操作之Script，包含自動化系統指令呼叫、系統修復、資料備份、資料還原、K8S指令呼叫等操作。",
                    tag: ['cli'],
                    isShown: true
                },
                {
                    name: "VB/VBA",
                    icon: "fa-regular fa-window-restore",
                    description: "以VBA開發Excel報表程式，動態擷取資料、計算並產出報表。",
                    tag: ['application'],
                    isShown: true
                },
                {
                    name: "Photoshop",
                    icon: "fa-solid fa-swatchbook",
                    description: "平面設計之視覺化調整，優化圖片與UI顯示風格。",
                    tag: ['uiux', 'design'],
                    isShown: true
                },
                {
                    name: "Illustrator",
                    icon: "fa-solid fa-pen-ruler",
                    description: "設計UI元件、LOGO以及部門活動意象與邀請函。",
                    tag: ['uiux', 'design'],
                    isShown: true
                },
                {
                    name: "Final Cut Pro",
                    icon: "fa-solid fa-photo-film",
                    description: "剪輯部門技術展示之影片、特效與配音作業。",
                    tag: ['design'],
                    isShown: true
                },
                {
                    name: "AutoCAD",
                    icon: "fa-solid fa-cubes",
                    description: "繪製智慧製造專案所需之3D圖面。",
                    tag: ['design'],
                    isShown: true
                },
                {
                    name: "SolidWorks",
                    icon: "fa-solid fa-cube",
                    description: "繪製智慧製造專案所需之設備3D圖。",
                    tag: ['design'],
                    isShown: true
                },
                {
                    name: "Git",
                    icon: "fa-brands fa-git",
                    description: "針對跨單位合作案、部門專案、個人專案進行版本控制與衝突修正。",
                    tag: ['cicd'],
                    isShown: true
                },
                {
                    name: "SVN",
                    icon: "fa-solid fa-code-branch",
                    description: "針對部門專案進行版本控制與衝突修正。",
                    tag: ['cicd'],
                    isShown: true
                },
                {
                    name: "JUnit",
                    icon: "fa-brands fa-uniregistry",
                    description: "以JUnit撰寫雲端系統自動化測試Test Case，確保功能可通過各項使用情境測試。",
                    tag: ['cicd'],
                    isShown: true
                },
                {
                    name: "JMeter",
                    icon: "fa-solid fa-meteor",
                    description: "撰寫API壓力測試，確保系統可呈受大量使用者、大量呼叫並正常服務。",
                    tag: ['cicd'],
                    isShown: true
                },
                {
                    name: "Jenkins",
                    icon: "fa-brands fa-jenkins",
                    description: "跨部門合作開發系統之品質管理，回報Bug、修正回覆等作業。",
                    tag: ['cicd'],
                    isShown: true
                },
                {
                    name: "Mantis BT",
                    icon: "fa-regular fa-bug",
                    description: "提供客戶端回報Bug及修正回覆之作業平台。",
                    tag: ['cicd'],
                    isShown: true
                },
                {
                    name: "Redmine",
                    icon: "fa-solid fa-r",
                    description: "部門開發進度管理與文件管理。",
                    tag: ['cicd'],
                    isShown: true
                },
                {
                    name: "ARKit",
                    icon: "fa-brands fa-unity",
                    description: "以iOS ARKit開發AR掃描、AR定位、AR物件設置、WorldMap儲存交換、GeoLocation定位、AR物件顯示與互動。",
                    tag: ['application'],
                    isShown: true
                },
                {
                    name: "SQL",
                    icon: "fa-solid fa-database",
                    description: "設計SQL Table Schema，關聯Table成View，並進行Query開發。",
                    tag: ['database'],
                    isShown: true
                },
                {
                    name: "HBase",
                    icon: "fa-solid fa-table",
                    description: "開發Hbase No SQL之Read、Write作業。",
                    tag: ['database'],
                    isShown: true
                },
                {
                    name: "nginx",
                    icon: "fa-solid fa-n",
                    description: "以Nginix即時進行HLS串流影片資訊及輸出具EME加密媒體擴充之媒體內容。",
                    tag: ['protocol'],
                    isShown: true
                },
                {
                    name: "OPC UA",
                    icon: "fa-solid fa-network-wired",
                    description: "擷取設備資料並轉成OPC UA輸出，或整合具備OPC UA輸出之設備資料。",
                    tag: ['protocol'],
                    isShown: true
                },
                {
                    name: "Web Socket",
                    icon: "fa-brands fa-rocketchat",
                    description: "以Web Socket方式進行Client端事件通知，包含異常通知、畫面更新、聲音播放、Client間對話服務。",
                    tag: ['protocol'],
                    isShown: true
                }
            ]
        },
        en: {
            skillTags: [ 
                {key: 'all', name: "All", isSelected: true}, 
                {key: 'frontend', name: "Frontend", isSelected: false}, 
                {key: 'backend', name: "Backend", isSelected: false}, 
                {key: 'application', name: "Application", isSelected: false},
                 {key: 'cli', name: "CLI", isSelected: false}, 
                 {key: 'uiux', name: "UI/UX", isSelected: false}, 
                 {key: 'design', name: "Design", isSelected: false}, 
                 {key: 'cicd', name: "CI/CD", isSelected: false}, 
                 {key: 'database', name: "Database", isSelected: false}, 
                 {key: 'protocol', name:"Protocol", isSelected:false} 
            ],
            skillInfo: [
                { name:"Javascript", icon:"fa-brands fa-square-js", description:"Familiar with JS technology and successfully developed N front-end service projects and 3 self-made system frameworks.", tag:['frontend'], isShown:true }, 
                { name:"HTML5", icon:"fa-brands fa-html5", description:"Construct front-end system web page layout and pay attention to front-end page design and development trends every year.", tag:['frontend'], isShown:true }, 
                { name:"React", icon:"fa-brands fa-react", description:"Using React framework, successfully created a human resource management and allocation tool for the department, calculating and analyzing the department's monthly cost, KPI and profit situation.", tag:['frontend'], isShown:true }, 
                { name:"Angular", icon:"fa-brands fa-angular", description:"Using Angular framework, created a cloud VM rental service website for a large Japanese telecom company.", tag:['frontend'], isShown:true }, 
                { name:"Vue", icon:"fa-brands fa-vuejs", description:"Using Vue framework, created a personal resume website.", tag:['frontend'], isShown:true },
                { name: "jQuery", icon: "fa-solid fa-q", description: "Perform DOM element manipulation with jQuery Library.", tag: ['frontend'], isShown: true }, 
                { name: "Typescript", icon: "fa-sharp fa-solid fa-t", description: "Use TS syntax to develop a cloud VM rental service for a large Japanese telecom company.", tag: ['frontend'], isShown: true }, 
                { name: "CSS", icon: "fa-brands fa-css3-alt", description: "Develop UI styles and animations with CSS syntax.", tag: ['frontend'], isShown: true }, 
                { name: "Java", icon: "fa-brands fa-java", description:"Develop back-end API Handler, Manager, Controller and perform database access.", tag:['backend', 'application'], isShown:true }, 
                { name:"Node.js", icon:"fa-brands fa-node-js", description:"Develop back-end API and perform database access.", tag:['backend'], isShown:true }, 
                { name:"JavaFX", icon:"fa-brands fa-java", description:"Develop desktop applications with JAVA.", tag:['application'], isShown:true }, 
                { name:"GO", icon:"fa-brands fa-golang", description:"Develop back-end API and perform cross-system Rest API calls.", tag:['backend'], isShown:true },
                { name: "Android JAVA", icon: "fa-brands fa-android", description: "Develop Android APP and Android Wear APP.", tag: ['application'], isShown: true }, 
                { name: "iOS Swift", icon: "fa-brands fa-swift", description: "Develop iPhone and iPad APP.", tag: ['application'], isShown: true }, 
                { name: "Axure RP", icon: "fa-solid fa-compass-drafting", description: "Perform UI Mockup, UX operation design and UI Prototype document output.", tag: ['uiux', 'design'], isShown: true }, 
                { name: "C", icon: "fa-solid fa-c", description:"Modify Linux OS kernel, develop Scheduling algorithm, predict CPU and Memory behavior according to Process, and perform CPU frequency adjustment to reduce energy consumption.", tag:['cli'], isShown:true }, 
                { name:"bash script", icon:"fa-solid fa-terminal", description:"Develop system operation scripts, including automated system command calls, system repair, data backup, data recovery, K8S command calls and other operations.", tag:['cli'], isShown:true }, 
                { name:"VB/VBA", icon:"fa-regular fa-window-restore", description:"Develop Excel report programs with VBA, dynamically capture data, calculate and generate reports.", tag:['application'], isShown:true }, 
                { name:"Photoshop", icon:"fa-solid fa-swatchbook", description:"Visual adjustment of graphic design, optimize image and UI display style.", tag:['uiux', 'design'], isShown:true },
                { name: "Illustrator", icon: "fa-solid fa-pen-ruler", description: "Design UI components, LOGO and department activity image and invitation.", tag: ['uiux', 'design'], isShown: true }, 
                { name: "Final Cut Pro", icon: "fa-solid fa-photo-film", description: "Edit department technical demonstration video, special effects and dubbing work.", tag: ['design'], isShown: true }, 
                { name: "AutoCAD", icon: "fa-solid fa-cubes", description: "Draw 3D drawings required for smart manufacturing projects.", tag: ['design'], isShown: true }, 
                { name: "SolidWorks", icon: "fa-solid fa-cube", description:"Draw 3D drawings of equipment required for smart manufacturing projects.", tag:['design'], isShown:true }, 
                { name:"Git", icon:"fa-brands fa-git", description:"Perform version control and conflict resolution for cross-unit collaboration cases, department projects and personal projects.", tag:['cicd'], isShown:true }, 
                { name:"SVN", icon:"fa-solid fa-code-branch", description:"Perform version control and conflict resolution for department projects.", tag:['cicd'], isShown:true }, 
                { name:"JUnit", icon:"fa-brands fa-uniregistry", description:"Write cloud system automated test Test Case with JUnit to ensure that functions can pass various use scenario tests.", tag:['cicd'], isShown:true },
                { name: "JMeter", icon: "fa-solid fa-meteor", description: "Write API stress test to ensure that the system can withstand a large number of users, a large number of calls and normal service.", tag: ['cicd'], isShown: true }, 
                { name: "Jenkins", icon: "fa-brands fa-jenkins", description: "Quality management of cross-departmental collaborative development system, bug reporting, correction reply and other operations.", tag: ['cicd'], isShown: true }, 
                { name: "Mantis BT", icon: "fa-regular fa-bug", description: "Provide a platform for client-side bug reporting and correction reply.", tag: ['cicd'], isShown: true }, 
                { name: "Redmine", icon: "fa-solid fa-r", description:"Department development progress management and document management.", tag:['cicd'], isShown:true }, 
                { name:"ARKit", icon:"fa-brands fa-unity", description:"Develop AR scanning, AR positioning, AR object placement, WorldMap storage exchange, GeoLocation positioning, AR object display and interaction with iOS ARKit.", tag:['application'], isShown:true },
                { name: "SQL", icon: "fa-solid fa-database", description: "Design SQL Table Schema, associate Table into View, and perform Query development.", tag: ['database'], isShown: true }, 
                { name: "HBase", icon: "fa-solid fa-table", description: "Develop Read and Write operations for Hbase No SQL.", tag: ['database'], isShown: true }, 
                { name: "nginx", icon: "fa-solid fa-n", description: "Use Nginix to perform HLS streaming video information and output media content with EME encryption media extension.", tag: ['protocol'], isShown: true }, 
                { name: "OPC UA", icon: "fa-solid fa-network-wired", description:"Capture device data and convert it to OPC UA output, or integrate device data with OPC UA output.", tag:['protocol'], isShown:true }, 
                { name:"Web Socket", icon:"fa-brands fa-rocketchat", description:"Use Web Socket method to perform Client-side event notification, including abnormal notification, screen update, sound playback, Client-to-Client chat service.", tag:['protocol'], isShown:true },
                
            ]
        }
      }
    }
}