<template>
    <!--<transition>-->
        <div class="entry col-md-6 col-lg-4 artInfo_container" v-if="myInfo.isShown" >
            <div class="grid-inner">
                <div class="entry-box-shadow">
                    <div class="entry-image mb-0 artInfo_imageContainer">
                        <a  data-lightbox="image"><img :src="getImageURL(myInfo.image)" :alt="myInfo.alt" class="artInfo_image"></a>
                    </div>
                    <div class="entry-meta-wrapper">
                        <div class="entry-title">
                            <h2><a class="artInfo_title">{{ myInfo.title }}</a></h2>
                        </div>
                        <div class="entry-meta mt-0">
                            <a class="text-muted"> {{ myInfo.subTitle }}</a>
                        </div>
                        <div class="entry-content mt-3 artInfo_content">
                            <p class="mb-0">{{ myInfo.description }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!--</transition>--> 
</template>

<script>
    import('../css/artInfo.css')

    export default {
        name: 'artInfo',
        props: {
            myInfo: Object,
            myIndex: Number,
            maxIndex: Number
        },
        methods: {
            getImageURL(imagePath) {
                return require("@/assets/" + imagePath)
            }
        }
    }
</script>
