import { createApp } from "vue/dist/vue.esm-bundler";
import App from './App.vue'
import VueAnimateOnScroll from 'vue3-animate-onscroll'
import VueI18n from 'vue-i18n'
import axios from 'axios'
import VueAxios from 'vue-axios'

//import VueRouter from 'vue-router'

const app = createApp(App)

/*
const routes = [
    {path: '/', redirect: '/home'},
    {path: '/home', name: 'Home', component: () => import('./components/HomePage/HomePage.vue')}
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
});
*/

app.use(VueAnimateOnScroll)
app.use(VueI18n)
app.use(VueAxios, axios)

//app.use(router)
app.mount('#app')
