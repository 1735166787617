<template>
    <div class="container professionalFrame">
        <img :src="getImageURL(myInfo.image)" class="professionalImage">
        <div class="professionalTitle">{{ myInfo.title }}</div>
        <div class="professionalContent" v-html="myInfo.contentHTML"></div>
    </div>
</template>

<script>
    import('../css/professional.css')
    export default {
        name: 'proInfo',
        props: {
            myInfo: Object,
            myIndex: Number,
            maxIndex: Number
        },
        methods: {
            getImageURL(imagePath) {
                return require("@/assets/" + imagePath)
            }
        },
    }
</script>
