export default {
    data() {
      return {
            tw: {
                artTags: [
                    {key: 'all', name: "全部", isSelected: true},
                    {key: 'logo', name: "LOGO", isSelected: false},
                    {key: 'uiux', name: "UI/UX", isSelected: false},
                    {key: 'invitation', name: "邀請函", isSelected: false},
                    {key: 'dm', name: "DM", isSelected: false},
                    {key: 'videocut', name: "影音剪輯", isSelected: false}
                ],
                artInfo: [
                            {
                                image: "art/Logo_SCADA.png",
                                title: "SCADA LOGO",
                                subTitle: "Photoshop",
                                description: "設計部門SCADA系統LOGO，以不同顏色象徵系統各種功能，組成一C字型代表Cloud SCADA之首要字母，並以六角型表現工業螺帽意象。",
                                tag: ['logo'],
                                isShown: true
                            },
                            {
                                image: "art/Logo_APM.png",
                                title: "APM LOGO",
                                subTitle: "Photoshop",
                                description: "設計Application Performance Monitor (APM) 操作說明書LOGO，以簡單的APM UI表現操作標的，並以綠色，及心電波形表現診斷活動。",
                                tag: ['logo'],
                                isShown: true
                            },
                            {
                                image: "art/Logo_AR.png",
                                title: "AR APP LOGO",
                                subTitle: "Photoshop, Illustrator",
                                description: "設計AR APP應用程式LOGO，以立體方塊與倒Y線段，代表顯示AR物件的3D視覺，及XYZ軸表象。",
                                tag: ['logo'],
                                isShown: true
                            },
                            {
                                image: "art/Logo_T3.png",
                                title: "部門LOGO",
                                subTitle: "Photoshop, Illustrator",
                                description: "設計部門LOGO，設計以大腦與線條象徵具備認知聯網，並結合部門不同開發產品之圖示，表現以中央平台化，整合不同功能產品之集合。",
                                tag: ['logo'],
                                isShown: true
                            },
                            {
                                image: "art/UI_Desktop.jpg",
                                title: "工業物聯網平台UI/UX設計",
                                subTitle: "Axure RP",
                                description: "負責部門工業物聯網平台桌面化服務UI與UX設計，包含SCADA、MES、WMS、碳管理等系統功能畫面設計。",
                                tag: ['uiux'],
                                isShown: true
                            },
                            {
                                image: "art/UI_SCADA.png",
                                title: "SCADA系統UI/UX設計",
                                subTitle: "Axure RP",
                                description: "負責SCADA系統UI與UX設計，包含監測書面、歷史圖表趨勢、自動化報表下載、管制條件設定、異常警報管理、使用者管理、權限管理、硬體管理等畫面設計。",
                                tag: ['uiux'],
                                isShown: true
                            },
                            {
                                image: "art/UI_APM.png",
                                title: "APM系統UI/UX設計",
                                subTitle: "Axure RP",
                                description: "負責APM系統UI/UX設計，包含應用路徑UI、細節顯示UI、異常追蹤UI與系統設定。",
                                tag: ['uiux'],
                                isShown: true
                            },
                            {
                                image: "art/UI_DISCO.png",
                                title: "雲端資料儲存設備管理UI/UX設計",
                                subTitle: "Axure RP",
                                description: "負責設計相容於Openstack之雲端資料儲存設備管理UI與UX，包含監測雲端伺服器狀態、硬碟狀態、LUN狀態、儲存效能檢測等功能UI/UX。",
                                tag: ['uiux'],
                                isShown: true
                            },
                            {
                                image: "art/UI_SOFA.png",
                                title: "資料儲存設備管理UI/UX設計",
                                subTitle: "Axure RP",
                                description: "負責設計資料儲存設備管理UI與UX，包含監測硬碟狀態、S.M.A.R.T、檢修與異常排除功能UI/UX。",
                                tag: ['uiux'],
                                isShown: true
                            },
                            {
                                image: "art/UI_HaaS.png",
                                title: "雲端VM租用平台UI/UX設計",
                                subTitle: "Axure RP",
                                description: "負責雲端VM租用平台UI/UX設計，包含規格選用、付款、管理與設定等功能之畫面設計。",
                                tag: ['uiux'],
                                isShown: true
                            },
                            {
                                image: "art/UI_APP.png",
                                title: "iOS/Android APP UI/UX設計",
                                subTitle: "Axure RP",
                                description: "負責多個APP UI/UX畫面設計，包含運動APP、iPad教練機APP、SCADA APP等。",
                                tag: ['uiux'],
                                isShown: true
                            },
                            {
                                image: "art/UI_LIMS.png",
                                title: "LIMS系統UI/UX設計",
                                subTitle: "Axure RP",
                                description: "負責LIMS系統UI與UX設計，包含實驗委託單申請、實驗數據填報、實驗報告審核、實驗結果歷程查詢、系統設定及使用者管理等系統功能與畫面設計。",
                                tag: ['uiux'],
                                isShown: true
                            },
                            {
                                image: "art/UI_Homepage.png",
                                title: "客戶委託官網UI/UX設計",
                                subTitle: "Axure RP",
                                description: "負責客戶委託之官網UI/UX設計，包含首頁畫面、產品畫面、3D套用畫面、後台管理、即時新聞等畫面設計。",
                                tag: ['uiux'],
                                isShown: true
                            },
                            {
                                image: "art/Invitation_2020.png",
                                title: "2020年部門尾牙邀請函",
                                subTitle: "Photoshop, Illustrator",
                                description: "設計2020年部門尾牙邀請函，以古典黃色，搭配玩數字文字遊戲：「3」字符號結合部門代碼T3與尾牙地點金色三麥，「T」字則結合了部門代碼T3與Thank。",
                                tag: ['invitation'],
                                isShown: true
                            },
                            {
                                image: "art/Invitation_2021.jpg",
                                title: "2021年部門尾牙邀請函",
                                subTitle: "Photoshop, Illustrator",
                                description: "尾牙舉辦時間正值過年前夕，使用適合年節的紅色，搭配模糊化光點，呈現年節與歡慶的氛圍。",
                                tag: ['invitation'],
                                isShown: true
                            },
                            {
                                image: "art/Invitation_2022.png",
                                title: "2022年部門尾牙邀請函",
                                subTitle: "Photoshop, Illustrator",
                                description: "2022年尾牙於高級飯店舉行，選用深藍色背景搭配金色前景與金屬光澤，表現該年度奢華的主題。",
                                tag: ['invitation'],
                                isShown: true
                            },
                            {
                                image: "art/Invitation_2023.png",
                                title: "2023年部門尾牙邀請函",
                                subTitle: "Photoshop, Illustrator",
                                description: "2023年是迎來疫情準備解封的一年，透過紙飛機表現來自遠端的書信，除了代表即將迎來遠距工作尾端之外，亦表現是來自數位遠端的祝福。",
                                tag: ['invitation'],
                                isShown: true
                            },
                            {
                                image: "art/DM_Post.png",
                                title: "智能製造體驗工坊介紹海報",
                                subTitle: "GIF, Photoshop",
                                description: "協助部門設計示範樂高工廠工作坊介紹海報，讓學員與參觀人員了解體驗工坊示範線流程與技術。",
                                tag: ['dm'],
                                isShown: true
                            },
                            {
                                image: "art/DM_T3.jpg",
                                title: "部門產品DM",
                                subTitle: "Coordinating",
                                description: "主動請求負責規劃部門產品DM，並與設計公司洽談風格與內容呈現方式。產品DM有助於團隊拜訪客戶、參展或訓練時，以圖像與書面展現部門完整開發技術能量。",
                                tag: ['dm'],
                                isShown: true
                            },
                            {
                                image: "art/DM_Customer.png",
                                title: "客戶參展背板設計",
                                subTitle: "Photoshop",
                                description: "協助客戶將所開發之系統，以圖像化呈現，設計前往國外參展之背板。",
                                tag: ['dm'],
                                isShown: true
                            },
                            {
                                image: "art/VIDEOCUT_NIP.png",
                                title: "技術展示影片剪輯",
                                subTitle: "Finalcut Pro",
                                description: "為提升部門技術展示效果，設計展示影片腳本、畫面擷取、過場剪輯與配音作業。",
                                tag: ['videocut'],
                                isShown: true
                            }
                        ]
            },
            en: {
                artTags: [
                    {key: 'all', name: "All", isSelected: true},
                    {key: 'logo', name: "LOGO", isSelected: false},
                    {key: 'uiux', name: "UI/UX", isSelected: false},
                    {key: 'invitation', name: "Invitation", isSelected: false},
                    {key: 'dm', name: "DM", isSelected: false},
                    {key: 'videocut', name: "Video Editing", isSelected: false}
                ],
                artInfo: [
                            { image: "art/Logo_SCADA.png", title: "SCADA LOGO", subTitle: "Photoshop", description: "Design the SCADA system LOGO of the design department, using different colors to represent various functions of the system, forming a C-shaped letter representing the first letter of Cloud SCADA, and using a hexagon to express the image of an industrial nut.", tag: ['logo'], isShown: true },
                            { image: "art/Logo_APM.png", title: "APM LOGO", subTitle: "Photoshop", description: "Design the LOGO of the Application Performance Monitor (APM) operation manual, using a simple APM UI to express the operation target, and using green and electrocardiogram waveform to express the diagnostic activity.", tag: ['logo'], isShown: true },
                            { image: "art/Logo_AR.png", title: "AR APP LOGO", subTitle: "Photoshop, Illustrator", description: "Design the LOGO of the AR APP application, using a three-dimensional cube and a reversed Y line segment to represent the 3D vision of displaying AR objects, and the XYZ axis.", tag: ['logo'], isShown: true },
                            { image: "art/Logo_T3.png", title: "Department LOGO", subTitle: "Photoshop, Illustrator", description: "Design the department LOGO, design the brain and lines to symbolize cognitive networking, and combine the icons of different development products in the department to express the collection of different functional products with central platformization and integration.", tag: ['logo'], isShown: true },
                            { image: "art/UI_Desktop.jpg", title: "Industrial IoT Platform UI/UX Design", subTitle: "Axure RP", description: "Responsible for the UI and UX design of the department's industrial IoT platform desktop services, including the design of system functions such as SCADA, MES, WMS, and carbon management.", tag: ['uiux'], isShown: true },
                            { image: "art/UI_SCADA.png", title: "SCADA System UI/UX Design", subTitle: "Axure RP", description: "Responsible for the UI and UX design of the SCADA system, including monitoring documents, historical chart trends, automatic report downloads, control condition settings, abnormal alarm management, user management, authority management, hardware management and other screen designs.", tag: ['uiux'], isShown: true },
                            { image: "art/UI_APM.png", title: "APM System UI/UX Design", subTitle: "Axure RP", description: "Responsible for the UI/UX design of the APM system, including the application path UI, detail display UI, abnormal tracking UI, and system settings.", tag: ['uiux'], isShown: true },
                            { image: "art/UI_DISCO.png", title: "Cloud Data Storage Device Management UI/UX Design", subTitle: "Axure RP", description: "Responsible for designing cloud data storage device management UI and UX compatible with Openstack, including monitoring cloud server status, disk status, LUN status, storage performance detection and other functional UI/UX.", tag: ['uiux'], isShown: true },
                            { image: "art/UI_SOFA.png", title: "Data Storage Device Management UI/UX Design", subTitle: "Axure RP", description: "Responsible for designing data storage device management UI and UX, including monitoring disk status, S.M.A.R.T, maintenance and abnormal troubleshooting functional UI/UX.", tag: ['uiux'], isShown: true },
                            { image: "art/UI_HaaS.png", title: "Cloud VM Rental Platform UI/UX Design", subTitle: "Axure RP", description: "Responsible for the UI and UX design of the cloud VM rental platform, including the design of functions such as specification selection, payment, management, and settings.", tag: ['uiux'], isShown: true },
                            { image: "art/UI_APP.png", title: "iOS/Android APP UI/UX Design", subTitle: "Axure RP", description: "Responsible for the UI/UX design of multiple APPs, including sports APP, iPad coach APP, SCADA APP, etc.", tag: ['uiux'], isShown: true }, 
                            { image: "art/UI_LIMS.png", title: "LIMS System UI/UX Design", subTitle: "Axure RP", description: "Responsible for the UI and UX design of the LIMS system, including the design of system functions and screens such as experiment commission application, experiment data filling, experiment report review, experiment result history inquiry, system settings, and user management.", tag: ['uiux'], isShown: true }, 
                            { image: "art/UI_Homepage.png", title: "Customer Commissioned Official Website UI/UX Design", subTitle: "Axure RP", description: "Responsible for the UI/UX design of the customer commissioned official website, including the design of screens such as the homepage, product screen, 3D application screen, backstage management, and real-time news.", tag: ['uiux'], isShown: true },
                            { image: "art/Invitation_2020.png", title: "2020 Department Year-end Party Invitation", subTitle: "Photoshop, Illustrator", description: "Designed the 2020 department year-end party invitation, using classical yellow, combined with digital text games: the \"3\" character symbol combined with the department code T3 and the year-end party location golden three wheat, the \"T\" character combined with the department code T3 and Thank.", tag: ['invitation'], isShown: true }, 
                            { image: "art/Invitation_2021.jpg", title: "2021 Department Year-end Party Invitation", subTitle: "Photoshop, Illustrator", description: "The year-end party was held just before the New Year, using red suitable for the New Year, combined with blurred light spots to present the atmosphere of the New Year and celebration.", tag: ['invitation'], isShown: true }, 
                            { image: "art/Invitation_2022.png", title: "2022 Department Year-end Party Invitation", subTitle: "Photoshop, Illustrator", description: "The 2022 year-end party was held at a high-end hotel, using a dark blue background with a golden foreground and metallic luster to express the luxurious theme of that year.", tag: ['invitation'], isShown: true },
                            { image: "art/Invitation_2023.png", title: "2023 Department Year-end Party Invitation", subTitle: "Photoshop, Illustrator", description: "2023 is the year when the epidemic is ready to be lifted. Through the paper airplane, it represents the letter from the remote. In addition to representing the end of the remote work, it also represents the blessings from the digital remote.", tag: ['invitation'], isShown: true }, 
                            { image: "art/DM_Post.png", title: "Intelligent Manufacturing Experience Workshop Introduction Poster", subTitle: "GIF, Photoshop", description: "Assisted the department in designing a Lego factory workshop introduction poster, allowing trainees and visitors to understand the demonstration line process and technology of the experience workshop.", tag: ['dm'], isShown: true }, 
                            { image: "art/DM_T3.jpg", title: "Department Product DM", subTitle: "Coordinating", description: "Actively requested to be responsible for planning the department's product DM, and negotiated with the design company on the style and content presentation method. The product DM helps the team to visit customers, participate in exhibitions or training, and demonstrate the department's complete development technical energy through images and text.", tag: ['dm'], isShown: true },
                            {
                                "image": "art/DM_Customer.png",
                                "title": "Customer Exhibition Backboard Design",
                                "subTitle": "Photoshop",
                                "description": "Assist customers in visualizing the system they developed by designing a backboard for overseas exhibitions.",
                                "tag": ["dm"],
                                "isShown": true
                            },
                            {
                                "image": "art/VIDEOCUT_NIP.png",
                                "title": "Technical Demonstration Video Editing",
                                "subTitle": "Finalcut Pro",
                                "description": "To enhance the technical display effect of the department, design the display video script, capture the screen, edit the transition and dubbing.",
                                "tag": ["videocut"],
                                "isShown": true
                            }
                        ]
            }
      }
    }
}