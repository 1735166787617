<template>
    <div class="localeButton_LocaleContainer">
        <div class="localeButton_LocaleText noselect" @click="toogleMenu()">
            <div class="fa-regular fa-earth-americas localeButton_earth"></div>
            <div class="localeButton_langFrame">{{ this.langList[this.myLang].name }}</div>
        </div>
        <transition>
            <div  class="localeButton_LocaleListContainer" v-if="this.isMenuShown == true">
                <div v-for="(item, index) in this.langList" :key="index" :class="index == myLang? 'localeButton_LocaleListItem_selected' : 'localeButton_LocaleListItem'" @click="onItemSelected(index)">{{ item.name }}</div>
            </div>
        </transition>
        
    </div>
    
</template>

<script>
    import('../css/localeButton.css')
    export default {
        name: 'localeButton',
        data: function(){
            return {
                myLang: this.currentLang,
                langList: {
                    tw: {name: '繁體中文'},
                    en: {name: 'English'}
                },
                isMenuShown: false
            }
        },
        props: {
            currentLang: String
        },
        created(){
        },
        methods: {
            toogleMenu(){
                this.isMenuShown = !this.isMenuShown
            },
            onItemSelected(lang){
                console.info(lang)
                this.myLang = lang;
                this.isMenuShown = false;
                this.$emit('onItemSelected', lang);
            }
        }
    }
</script>