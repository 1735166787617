<template>
    <div class="stretched ">
    <!-- Document Wrapper
    ============================================= -->
    <transition>
        <popupPanel v-if="popupPanelVisible" hasFooter="false" :title="popupPanelTitle" :projectDetail="currentPopupDetail" @closePanl="handleHidePopupPanel"></popupPanel>
    </transition>
    <div id="home" class="clearfix" >
        <!-- Header
        ============================================= -->
        <header id="header" class="transparent-header sticky-transparent homepage-header" data-sticky-shrink="false">
            <div id="header-wrap">
                <div class="container">
                    <div class="header-row">

                        <!-- Logo
                        ============================================= -->
                        <div id="logo">
                            <a href="index.html" class="standard-logo font-secondary ls3" style="line-height: 90px;">MK</a>
                            <a href="index.html" class="retina-logo font-secondary ls3" style="line-height: 90px;">MK</a>
                        </div><!-- #logo end -->

                        <div id="primary-menu-trigger" @click="handleMenuClick">
                            <svg class="svg-trigger" viewBox="0 0 100 100"><path d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"></path><path d="m 30,50 h 40"></path><path d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"></path></svg>
                        </div>

                        <!-- Primary Navigation
                        ============================================= -->
                        <nav class="primary-menu">

                            <ul ref="menuElement" class="menu-container one-page-menu custom-spacing" data-easing="easeInOutExpo" data-speed="1250" data-offset="0" style="color: white">
                                <li v-for="(item, index) in menuList" :key="index" :class="currentHash===item.dataHref ? 'menu-item current' : 'menu-item'">
                                    <a class="menu-link" :href="item.dataHref" :data-href="item.dataHref"><i :class="item.icon"></i><div class="homepage-menuText">{{ item.name }}</div></a>
                                </li>
                            </ul>

                        </nav><!-- #primary-menu end -->
                        <localeButton :currentLang="this.myLang"  @onItemSelected="handleLanguageSet"></localeButton>

                    </div>
                </div>
            </div>
            <div class="header-wrap-clone"></div>
        </header><!-- #header end -->
        <section id="slider" class="slider-element min-vh-60 min-vh-md-100 include-header">
            <div class="vh-100 vw-100 position-fixed homepage-cover">
                <div class="container">
                        <div class="slider-caption dark slider-caption-right">
                            <div>
                                <h2 class="font-primary ls5 animate__animated animate__fadeInLeft animate__delay-2s homepage-headerName-bg" >{{ introInfo.name }}</h2>
                                <h2 class="font-primary ls5 animate__animated animate__fadeInLeft animate__delay-2s homepage-headerName-bg" style="font-size: 50px" >{{ introInfo.subName }}</h2>
                                <p class="font-weight-light ls1 d-none d-sm-block animate__animated animate__fadeInRight animate__delay-2s"  style="color:#dfdfdf; font-weight: bolder;">{{ introInfo.basicIntro }}<br>{{ introInfo.locationIntro }}</p>
                                <a class="font-primary border-0 ls1 topmargin-sm inline-block more-link text-white dark d-none d-sm-inline-block animate__animated animate__pulse  animate__infinite">{{ introInfo.slideToView }}</a>
                            </div>
                            <div>
                                <div v-for="(item, index) in introInfo.downloadButtonList"  :key="index" :class="item.downloadable == true? 'downloadButton' : 'downloadButton cantDownloadButton'" @click="downloadFile(item)">{{ item.text }}</div>
                            </div>
                        </div>
                </div>
                <div class="content-wrap pb-0 bg-transparent homepage-arrow">
                    <img src="./images/down.png" class="animate__animated animate__slowest animate__shakeY animate__infinite" style="width: 80px; height: 80px" alt="Image">
                </div>

            </div>

            <div ref="blurCover" class="vh-100 vw-100 position-fixed blurred-img homepage-cover blurred-image-visible" :style="{'opacity': blurOpacity}"></div>


        </section>
        <!-- 
        =============================================
            Content
        ============================================= 
        -->
        <section id="content" class="bg-transparent" style="margin-top:200px">
            <div class="content-wrap pb-0 bg-transparent">
                <!-- 
                =============================================
                    個人資料
                ============================================= 
                -->
                <div id="basicInfo" ref="basicInfo" class="section m-0 page-section dark bg-transparent" style="padding-bottom: 50px">
                    <div class="container">
                        <div class="heading-block center">
                            <h2 class="font-secondary">{{ sectionTitleInfo.professionalData }}</h2>
                        </div>
                        <div class="row justify-content-center col-mb-50 mb-0">
                            <div class="col-sm-6 col-lg-4">
                                <div class="font-weight-bolder ls2 homePage-basicInfoCardTitle">{{ sectionTitleInfo.basic }}</div>
                                <div class="homePage-basicInfoCard"  style="height:380px">
                                    <basicInfo v-bind:myInfo="item" v-for="(item, index) in basicInfo" :key="index"></basicInfo>
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-4">
                                <div class="font-weight-bolder ls2 homePage-basicInfoCardTitle">{{ sectionTitleInfo.work }}</div>
                                    <eduInfo :myInfo="item" :myIndex="index" :maxIndex="Object.keys(careerInfo).length - 1" v-for="(item, index) in careerInfo" :key="index"></eduInfo>
                                <div class="homePage-basicInfoCard" style="height:380px">
                                    
                                    
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-4">
                                <div class="font-weight-bolder ls2 homePage-basicInfoCardTitle">{{ sectionTitleInfo.education }}</div>
                                <div class="homePage-basicInfoCard">
                                    <eduInfo :myInfo="item" :myIndex="index" :maxIndex="Object.keys(educationInfo).length - 1" v-for="(item, index) in educationInfo" :key="index"></eduInfo>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 
                =============================================
                    專業能力
                ============================================= 
                -->
                <div id="professional" ref="professional" class="section m-0 page-section dark bg-transparent" style="padding-bottom: 50px">
                    <div class="container">
                        <div class="heading-block center">
                            <h2 class="font-secondary">{{ sectionTitleInfo.professional }}</h2>
                        </div>
                        <proInfo :myInfo="item" :myIndex="index" v-for="(item, index) in professionalInfo" :key="index"></proInfo>
                    </div>
                </div>
                <!-- 
                =============================================
                    開發技能
                ============================================= 
                -->
                <div id="skills" ref="skills" class="section m-0 page-section dark bg-transparent" style="padding-bottom: 50px">
                    <div class="container">
                        <div class="heading-block center">
                            <h2 class="font-secondary">{{ sectionTitleInfo.skills }}</h2>
                        </div>
                        <div class="container">
                            <tagFilter :tagList="this.skillTags" @onTagSelected="handleSkillTagSelected"></tagFilter>
                        </div>
                        <div class="row justify-content-center col-mb-50 mb-0 homePage-skillFrame">
                            <skillInfo :myInfo="item" :myIndex="index" v-for="(item, index) in skillInfo" :key="index"></skillInfo>
                        </div>

                    </div>
                </div>

                <!-- 
                =============================================
                    執行專案
                ============================================= 
                -->
                <div id="projects" ref="projects" class="section page-section m-0 project-bg clearfix" style="padding: 100px 0">
                    <div class="container clearfix">

                        <div class="dark">
                            <div class="heading-block center">
                                <h2 class="font-secondary">{{ sectionTitleInfo.projects }}</h2>
                                <span class="mt-0" style="color: white;">{{ sectionDescription.project }}</span>
                            </div>
                        </div>
                        <div class="container">
                            <tagFilter :tagList="this.projectTags" @onTagSelected="handleProjectTagSelected"></tagFilter>
                        </div>
                        <div id="posts" class="post-grid row gutter-30 mb-0" data-layout="fitRows">
                            <projectInfo :myInfo="item" :myIndex="index" v-for="(item, index) in projectInfo" :key="index" @showProjectPanel="handleShowPopupPanel" :viewMoreText="subSectionTitleInfo.viewMore"></projectInfo>
                        </div>

                    </div>
                </div>
                <!-- 
                =============================================
                    設計
                ============================================= 
                -->
                <div id="design" ref="design" class="section page-section m-0 design-bg clearfix" style="padding: 100px 0px 0px 0px">
                    <div class="container clearfix">

                        <div class="dark">
                            <div class="heading-block center">
                                <h2 class="font-secondary">{{ sectionTitleInfo.design }}</h2>
                                <span class="mt-0" style="color: white;">{{ sectionDescription.design }}</span>
                            </div>
                        </div>
                        <div class="wave"></div>
                        <div class="wave"></div>
                        <div class="wave"></div>
                        <div class="container">
                            <tagFilter :tagList="this.artTags" @onTagSelected="handleArgTagSelected"></tagFilter>
                        </div>
                        <div id="posts" class="post-grid row gutter-30 mb-0" data-layout="fitRows">
                            <artInfo :myInfo="item" :myIndex="index" v-for="(item, index) in artInfo" :key="index"></artInfo>
                        </div>

                    </div>
                </div>
                <!-- 
                =============================================
                    個性與興趣
                ============================================= 
                -->
                <div id="interest" ref="interest" class="section m-0 page-section dark bg-transparent" style="padding-bottom: 50px">
                    <div class="container">
                        <div class="heading-block center">
                            <h2 class="font-secondary">{{ sectionTitleInfo.interest }}</h2>
                            <span class="mt-0" style="font-size: 1.25rem; text-align: justify; color: white;">{{ sectionDescription.personality_1 }}</span>
                            <span class="mt-0" style="font-size: 1.25rem; text-align: justify; color: white; margin-top: 10px !important;">{{ sectionDescription.personality_2 }}</span>
                            <span class="mt-0" style="font-size: 1.25rem; text-align: justify; color: white; margin-top: 10px !important;">{{ sectionDescription.personality_3 }}</span>
                        </div>
                        <span class="mt-0 section_subTitle">{{ subSectionTitleInfo.personality }}</span>
                        <interestInfo :myInfo="item" v-for="(item, index) in myPersonalityInfo" :key="index" @showInterestPanel="handleShowPopupPanel" :detailText="subSectionTitleInfo.detail"></interestInfo>
                        <span class="mt-0 section_subTitle">{{ subSectionTitleInfo.interest }}</span>
                        <interestInfo :myInfo="item" v-for="(item, index) in myInterestInfo" :key="index"  @showInterestPanel="handleShowPopupPanel" :detailText="subSectionTitleInfo.detail"></interestInfo>
                    </div>
                </div>
                <!-- 
                =============================================
                    著作與認證
                ============================================= 
                -->
                <div id="articles" ref="articles" class="section m-0 page-section dark" style="padding-bottom: 50px; background-color: #323a3abf;">
                    <div class="container">
                        <div class="heading-block center">
                            <h2 class="font-secondary">{{ sectionTitleInfo.publish }}</h2>
                        </div>
                        <publishInfo :myInfo="item" v-for="(item, index) in myPublishInfo" :key="index"></publishInfo>
                    </div>
                </div>
            </div>
        </section><!-- #content end -->

        <!-- Footer
        ============================================= -->
        <footer id="footer" class="page-section dark border-0 p-0 clearfix" style="background-color: #1C1C1C;">
            <div id="contact" class="container clearfix">
                <!-- Footer Widgets
                ============================================= -->
                <div class="footer-widgets-wrap clearfix" style="padding: 80px 0">

                    <div class="row col-mb-50">
                        <div class="col-lg-3">
                            <div class="footer-logo"><span class="font-weight-normal color ls1" style="font-size: 22px; ">Mu-Kai Huang's Resume</span><br><small class="ls3 text-uppercase" style="color: rgba(255,255,255,0.2);">&copy; 2023 Reserved.</small></div>
                        </div>

                        <div class="col-lg-9">

                            <div class="row col-mb-50">
                                <div class="col-lg-6 col-lg-4">
                                    <div class="widget">
                                        <h4>{{ sectionTitleInfo.contect }}</h4>
                                        <div class="footer-content">
                                            <strong>{{ subSectionTitleInfo.tel }}</strong> {{ sectionDescription.phone }}<br>
                                            <strong>{{ subSectionTitleInfo.mail }}</strong> {{ sectionDescription.mail }}
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-lg-4">
                                    <div class="widget">
                                        <h4>{{ sectionTitleInfo.preferLocation }}</h4>
                                        <div class="footer-content">
                                            <address class="mb-0">
                                                {{ sectionDescription.location }}<br>
                                                {{ sectionDescription.remote }}<br>
                                            </address>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <!-- Copyrights
            ============================================= -->
            <div id="copyrights" style="background-color: #111;">
                <div class="container clearfix">

                    <div class="w-100 text-center">
                        <p class="mb-3">{{ sectionDescription.copyright }}</p>
                    </div>

                </div>
            </div><!-- #copyrights end -->
        </footer><!-- #footer end -->
    </div><!-- #wrapper end -->

    </div>
</template>

<script>
    import('../style.css')
    import('../AddOns/css/bootstrap.css')
    
    import('./css/HomePage.css')
    import('./css/fonts.css')

    import('../AddOns/css/font-icons.css')
    import('../AddOns/css/animate-4.1.1.css')
    import('../AddOns/css/animate.min.css')
    import('../AddOns/css/magnific-popup.css')
    import('../AddOns/css/colors.css')
    
    import('../AddOns/css/custom.css')
    import('../AddOns/css/dark.css')
    import('./css/HomePage-Template.css')
    
    import('../AddOns/css/fontawesome/fontawesome.css')
    import('../AddOns/css/fontawesome/brands.css')
    import('../AddOns/css/fontawesome/solid.css')

    import basicInfo from './components/basicInfo.vue'
    import eduInfo from './components/eduInfo.vue'
    import proInfo from './components/proInfo.vue'
    import skillInfo from './components/skillInfo.vue'
    import projectInfo from './components/projectInfo.vue'
    import popupPanel from './components/popupPanel.vue'
    import tagFilter from './components/tagFilter.vue'
    import artInfo from './components/artInfo.vue'
    import interestInfo from './components/interestInfo.vue'
    import publishInfo from './components/publishInfo.vue'
    import localeButton from './components/localeButton.vue'

    import basicData from './data/basicData.js'
    import professionalData from './data/professionalData.js'
    import skillData from './data/skillData.js'
    import projectData from './data/projectData.js'
    import artData from './data/artData.js'
    import interestData from './data/interestData.js'
    import publishData from './data/publishData.js'

    export default {
        name: 'HomePage',
        props: {
            msg: String,
        },
        created() {
            this.setViewport();
            window.addEventListener("scroll", this.handleScroll);
            this.handleScroll();
            this.currentHash = location.hash;
            if(this.currentHash == '') this.currentHash = '#home';
            window.addEventListener('hashchange', this.onHashChange, false)
            this.detectLang(navigator.language);
            console.info(this.myLang)
            //console.log(this.$root.$i18n.locale);
        },
        components: {
            basicInfo,
            eduInfo,
            proInfo,
            skillInfo,
            projectInfo,
            popupPanel,
            tagFilter,
            artInfo,
            interestInfo,
            publishInfo,
            localeButton
        },
        data: function () {
            return {
                myLang: 'tw',
                blurOpacity: 0,
                popupPanelVisible: false,
                popupPanelTitle: '',
                currentHash: '',
                currentPopupDetail: null,
                isMenuOn: false,
                introInfo: basicData.data()['tw'].introInfo,
                sectionTitleInfo: basicData.data()['tw'].sectionTitleInfo,
                subSectionTitleInfo: basicData.data()['tw'].subSectionTitleInfo,
                sectionDescription: basicData.data()['tw'].sectionDescription,
                menuList: basicData.data()['tw'].menuInfo.menuList,
                basicInfo: basicData.data()['tw'].basicInfo,
                careerInfo: basicData.data()['tw'].careerInfo,
                educationInfo: basicData.data()['tw'].educationInfo,
                professionalInfo: professionalData.data()['tw'].professionalInfo,
                skillTags: skillData.data()['tw'].skillTags,
                skillInfo: skillData.data()['tw'].skillInfo,
                projectInfo: projectData.data()['tw'].projectInfo,
                projectTags: projectData.data()['tw'].projectTags,
                artTags: artData.data()['tw'].artTags,
                artInfo: artData.data()['tw'].artInfo,
                myPersonalityInfo: interestData.data()['tw'].myPersonalityInfo,
                myInterestInfo: interestData.data()['tw'].myInterestInfo,
                myPublishInfo: publishData.data()['tw'].myPublishInfo
            }
        },
        methods: { 
            downloadFile(item){
                if(item.downloadable != true) return;
                var fileName = item.fileName;
                this.axios({
                method: 'get',
                url: "./api/downloadFile",
                params: {
                    fileName: fileName
                },
                responseType: 'arraybuffer'
                }).then(response => {
                    this.generateDownloadFile(fileName, response)
                    console.info(response)
                }).catch((error) => {
                    console.info(error)
                })
                /*this.axios.get("./api/downloadFile?fileName=MuKaiHuang_Portfolio_Chinese.pdf").then((response) => {
                    console.log(response.data)
                })*/
            },
            generateDownloadFile(fileName, response) {
                if (!response) {
                return
                }
                let url = window.URL.createObjectURL(new Blob([response.data]))
                let link = document.createElement('a')
                link.style.display = 'none'
                link.href = url
                link.setAttribute('download', fileName)

                document.body.appendChild(link)
                link.click()
            },
            detectLang(lang){
                if(lang.startsWith('zh-')) this.myLang = 'tw';
                else if(lang.startsWith('en-')) this.myLang = 'en';
                else this.myLang = 'en';
            },
            handleLanguageSet(lang){
                this.myLang = lang;
                this.switchLocaleData();
            },
            switchLocaleData(){
                this.introInfo = basicData.data()[this.myLang].introInfo;
                this.sectionTitleInfo = basicData.data()[this.myLang].sectionTitleInfo;
                this.subSectionTitleInfo = basicData.data()[this.myLang].subSectionTitleInfo;
                this.sectionDescription = basicData.data()[this.myLang].sectionDescription;
                this.menuList = basicData.data()[this.myLang].menuInfo.menuList;
                this.basicInfo = basicData.data()[this.myLang].basicInfo;
                this.careerInfo = basicData.data()[this.myLang].careerInfo;
                this.educationInfo = basicData.data()[this.myLang].educationInfo;
                this.professionalInfo = professionalData.data()[this.myLang].professionalInfo;
                this.skillTags = skillData.data()[this.myLang].skillTags;
                this.skillInfo = skillData.data()[this.myLang].skillInfo;
                this.projectInfo = projectData.data()[this.myLang].projectInfo;
                this.projectTags = projectData.data()[this.myLang].projectTags;
                this.artTags = artData.data()[this.myLang].artTags;
                this.artInfo = artData.data()[this.myLang].artInfo;
                this.myPersonalityInfo = interestData.data()[this.myLang].myPersonalityInfo;
                this.myInterestInfo = interestData.data()[this.myLang].myInterestInfo;
                this.myPublishInfo = publishData.data()[this.myLang].myPublishInfo;
            },
            onHashChange(){
                this.currentHash = location.hash;
            },
            handleMenuClick(){
                console.info(this.$refs.menuElement)
                if(this.isMenuOn){
                    this.$refs.menuElement.classList.remove('showMenu');
                }
                else{
                    this.$refs.menuElement.classList.add('showMenu');
                }
                this.isMenuOn = !this.isMenuOn
            },

            handleShowPopupPanel(popupInfo){
                this.popupPanelTitle = popupInfo.title;
                this.currentPopupDetail = popupInfo;
                this.popupPanelVisible = true;
                
                document.body.classList.add('homepage-noScroll');
            },
            handleHidePopupPanel(){
                this.currentPopupDetail = null;
                this.popupPanelVisible = false;
                document.body.classList.remove('homepage-noScroll');
            },
            examTagsForSingleSelection(targetTagList, selectedTag, keyOfAll){
                var allTagSelected = false;
                var selectedTagKeyList = [];
                var previousSelectedTag = null;
                targetTagList.forEach(function(currentTag){
                    if(currentTag.isSelected) previousSelectedTag = currentTag;
                });
                if(previousSelectedTag != null && previousSelectedTag.key != selectedTag.key){
                    previousSelectedTag.isSelected = false;
                }
                selectedTag.isSelected = true;
                if(selectedTag.key == keyOfAll) allTagSelected = true;
                else selectedTagKeyList.push(selectedTag.key);
                return {
                    allTagSelected: allTagSelected,
                    selectedTagKeyList: selectedTagKeyList
                }
            },
            examTags(targetTagList, selectedTag, keyOfAll){
                var allTagSelected = false;
                var selectedTagKeyList = [];
                //check selected tags
                if(selectedTag.key == keyOfAll){
                    selectedTag.isSelected = true;
                    targetTagList.forEach(function(currentTag){
                        if(currentTag.key != keyOfAll){
                            currentTag.isSelected = false;
                        }
                    });
                    allTagSelected = true;
                }
                else{
                    selectedTag.isSelected = !selectedTag.isSelected;
                    var tagOfAll = null;
                    var noTagSelected = true;
                    targetTagList.forEach(function(currentTag){
                        if(currentTag.key == keyOfAll){
                            tagOfAll = currentTag;
                        }
                        if(currentTag.key != keyOfAll && currentTag.isSelected == true){
                            noTagSelected = false;
                            selectedTagKeyList.push(currentTag.key);
                        }
                    });
                    if(noTagSelected == false){
                        tagOfAll.isSelected = false;
                    }
                    if(noTagSelected == true && tagOfAll != null){
                        tagOfAll.isSelected = true;
                        allTagSelected = true;
                    }
                }
                return {
                    allTagSelected: allTagSelected,
                    selectedTagKeyList: selectedTagKeyList,
                }
            },
            handleSkillTagSelected(selectedTag){
                //var examResult = this.examTags(this.skillTags, selectedTag, "all");
                var examResult = this.examTagsForSingleSelection(this.skillTags, selectedTag, "all");
                var allTagSelected = examResult.allTagSelected;
                var selectedTagKeyList = examResult.selectedTagKeyList;
                //filter skills
                this.skillInfo.forEach(function(currentSkill){
                    if(allTagSelected) currentSkill.isShown = true;
                    else if(currentSkill.tag.some( tag => selectedTagKeyList.includes(tag))){
                        currentSkill.isShown = true;
                    }
                    else currentSkill.isShown = false;
                });
            },
            handleProjectTagSelected(selectedTag){
                //var examResult = this.examTags(this.projectTags, selectedTag, "all");
                var examResult = this.examTagsForSingleSelection(this.projectTags, selectedTag, "all");
                var allTagSelected = examResult.allTagSelected;
                var selectedTagKeyList = examResult.selectedTagKeyList;
                //filter projects
                this.projectInfo.forEach(function(currentProject){
                    if(allTagSelected) currentProject.isShown = true;
                    else if(currentProject.tag.some( tag => selectedTagKeyList.includes(tag))){
                        currentProject.isShown = true;
                    }
                    else currentProject.isShown = false;
                });
            },
            handleArgTagSelected(selectedTag){
                //var examResult = this.examTags(this.artTags, selectedTag, "all");
                var examResult = this.examTagsForSingleSelection(this.artTags, selectedTag, "all");
                var allTagSelected = examResult.allTagSelected;
                var selectedTagKeyList = examResult.selectedTagKeyList;
                //filter skills
                this.artInfo.forEach(function(currentArt){
                    if(allTagSelected) currentArt.isShown = true;
                    else if(currentArt.tag.some( tag => selectedTagKeyList.includes(tag))){
                        currentArt.isShown = true;
                    }
                    else currentArt.isShown = false;
                });
            },
            handleScroll() {
                var pixs = window.scrollY;
                var opacity = pixs / 650;
                this.blurOpacity = Math.min(1, opacity);
                const refList = ['home', 'basicInfo', 'professional', 'skills', 'projects', 'design', 'interest', 'articles', 'contact'];
                var isHashDetected = false;
                for(var i=0; i<refList.length; i++){
                    var currentRef = refList[i];
                    if(!isHashDetected && this.$refs[currentRef] != null){
                        var currentElement = this.$refs[currentRef];
                        const top = currentElement.getBoundingClientRect().top;
                        if(top > 0 && top < (window.innerHeight / 2)){
                            this.currentHash = '#' + currentElement.id;
                            isHashDetected = true;
                            break;
                        }
                    }
                }
                if(!isHashDetected && pixs < (window.innerHeight / 3)){
                    this.currentHash = '#home';
                }
                else if(!isHashDetected && pixs >= (document.body.scrollHeight - 1000)){
                    this.currentHash = '#contact';
                }
            },
            setViewport: function() {
                let viewportContent = "width=device-width, initial-scale=1"
                document.querySelector("meta[name='viewport']").setAttribute("content", viewportContent)
            }
        }
    }
</script>