export default {
    data() {
      return {
        tw: {
            myPersonalityInfo: [
                {
                   image: 'interest/finder.jpg',
                   title: '積極尋求解方',
                   content: 'Solution Finder：喜歡尋求改善技術或工具讓作業更便利。',
                   detailContent: {
                        contentList: [
                            {type: 'imageElement', description: '為提升工作效率，自主開發規劃人力成本軟體，快速完成工作取代原本需用Excel完成估算的耗時工作', content: 'project/detail/department/before_after.jpg'},
                            {type: 'imageElement', description: '使用Home Assistant整合家電，不用查看手機便能通知家電運作狀況', content: 'interest/detail/smarthome/homeassistant_structure.png'},
    
                        ]
                    },
                },
                {
                   image: 'interest/share.jpg',
                   title: '分享與助人',
                   content: 'Helper：願意為組織、他人服務，幫助與分享所能及事務。',
                   detailContent: {
                        contentList: [
                            {type: 'textElement', content: '我樂意分享與為團隊服務，例如在工研院服務期間，我主動建立部門團隊使用的NAS系統，並負責後續維護更新；也主動建立團隊使用的SVN、Gitlab平台，並建立團隊使用的Bug Tracker平台，供成員們及客戶回報系統Bug。另外我也主動負責辦理部門的聚餐及尾牙活動，為團隊服務凝聚向心力。在工作之餘，也會參加環保團體舉辦的淨灘活動，及友善動物活動。'},
                            {type: 'imageElement', description: '主動為部門服務，建立軟體平台與採購軟體', content: 'interest/detail/service/service.png'},
                            {type: 'imageElement', description: '主動參加淨灘活動', content: 'interest/detail/service/clean.jpg'},
                            {type: 'imageElement', description: '友善動物活動', content: 'interest/detail/service/dog.jpg'},
    
                        ]
                    },
                },
                {
                    image: 'interest/newthing.jpg',
                    title: '接受新事物',
                    content: 'Early Adopter：我樂於嚐鮮，喜歡接觸新技術與新產品。',
                    detailContent: {
                     contentList: [
                             {type: 'textElement', content: '我喜歡嚐試新的產品，當第一代產品發行時，都十分願意購買使用，新版的的軟體釋出或新的作業系統發行時，也會進行使用。另外我也經常在募資平台資助新的產品，例如犀牛盾手機殼、行動式手錶充電盒、桌上型空氣清淨機、國人自製電玩遊戲、集資公益廣告...等。'},
                         ]
                     },
                 },
            ],
            myInterestInfo: [
                {
                    image: 'interest/room.jpg',
                    title: '美學設計',
                    content: '接觸設計美學：平面設計與室內軟裝擺設。',
                    detailContent: {
                        contentList: [
                            {type: 'textElement', content: '閒暇之餘，我喜歡透過擺飾、光源、智慧家電等軟裝方式，裝修自己的生活環境，提升生活品質。此外也會設計禮物包裝，增加生活儀式感。'},
                            {type: 'imageElement', description: '室內軟裝', content: 'interest/detail/design/interial.jpg'},
                            {type: 'imageElement', description: '禮物包裝', content: 'interest/detail/design/gift.jpg'},
                        ]
                    },
                },
                {
                    image: 'interest/smarthome.jpg',
                    title: '智慧家電',
                    content: '研究智慧家庭：原生智慧家電 + 跨廠牌整合。',
                    detailContent: {
                        contentList: [
                            {type: 'textElement', content: '在日常生活中，我喜歡將自己的專長應用在生活，例如在智慧家庭方面，我希望能夠在單一的手機平台，就能監控操作家中聯網的家電。然而就我所使用的Apple手機中，並不是所有家電都支援Apple Homekit，因此我經常研究如何將非Homekit家電連入Homekit中，以下有兩個例子說明。'},
                            {type: 'imageElement', description: 'Home Assistant整合架構', content: 'interest/detail/smarthome/homeassistant_structure.png'},
                            {type: 'textElement', content: '第1個例子是我透過Home Assistant，將不支援Homekit的LG洗衣機、小米家電，透過Github上高手開發的套件，與家電原廠的Server API連動，並透過Home Assistant的Airplay套件及Homekit套件，便能將不支援Homekit的家電連入Homekit中，進行生活便利應用。例如當洗衣機完成時，透過Homepod通知洗衣機完成，如此就不用再打開APP或拿起手機查看。'},
                            {type: 'iFrameVideoElement', description: '不支援Homekit的LG洗衣機完成後透過Airplay至Homekit中Homepod播放完成音效', content: '<iframe width="410" height="729" src="https://www.youtube.com/embed/KhopxdVDxfk" title="洗衣機洗好了" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'},
                            {type: 'iFrameVideoElement', description: '不支援Homekit的LG乾衣機完成後透過Airplay至Homekit中Homepod播放完成音效', content: '<iframe width="410" height="729" src="https://www.youtube.com/embed/__Iy13QvYj4" title="乾衣機烘好了" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'},
                            {type: 'iFrameVideoElement', description: '整合Google TTS 播放聲音 (但太不自然因此不採用)', content: '<iframe width="410" height="729" src="https://www.youtube.com/embed/BQZ-uI7JUaE" title="TTS聲音很怪" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'},
                            {type: 'textElement', content: '第2個例子是我透過Scrypted，將原本家中不支援Homekit的攝影機以RTSP串流並透過OpenCV套件偵測移動，再透過Homekit外掛模組偽裝成支援Homekit的攝影機。這樣的好處是我可以使用Apple Secure Video功能，直接偵測有人、動物、包裏出現事件，並啟動錄影儲存在iCloud中，從手機就可以直接追查什麼時間點出現了人、動物、包裏的片段，而不用從原本CCTV中快轉回放找事件。'},
                            {type: 'imageElement', description: 'Scrypted整合架構', content: 'interest/detail/smarthome/scrypted_structure.png'},
                            {type: 'iFrameVideoElement', description: '偵測到小貓直接在手機看到該事件 (也能透過手機通知)', content: '<iframe width="410" height="729" src="https://www.youtube.com/embed/OYjDUvLMLUU" title="偵測有貓" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'},
    
                        ]
                    },
                },
                {
                    image: 'interest/workout.jpg',
                    title: '運動健身',
                    content: '路跑、健身：保持一年一路跑賽、一週四練運動習慣。',
                    detailContent: {
                        contentList: [
                            {type: 'textElement', content: '平常，我喜歡路跑與健身等動態活動，並保持一年參加一路跑賽，以及一週四練的規律運動習慣。為養成良好運動習慣，我也在家裡建立一套自己的Home Gym，將運動帶入生活，並將自律的控制飲食，每日記錄飲食碳水、蛋白質、脂肪比例。'},
                            {type: 'iFrameVideoElement', description: '重訓教練課', content: '<iframe width="410" height="729" src="https://www.youtube.com/embed/_1DRPPZ_vrc" title="重訓教練課_1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'},
                            {type: 'iFrameVideoElement', description: '重訓教練課', content: '<iframe width="410" height="729" src="https://www.youtube.com/embed/2AKjGIHlPw0" title="重訓教練課_2" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'},
                            {type: 'imageElement', description: '路跑', content: 'interest/detail/sport/run.jpg'},
                            {type: 'imageElement', description: 'Home Gym', content: 'interest/detail/sport/homegym1.jpg'},
                            {type: 'imageElement', description: 'Home Gym配件', content: 'interest/detail/sport/homegym2.jpg'},
                        ]
                    },
                },
                {
                    image: 'interest/game.jpg',
                    title: '電玩',
                    content: '喜歡玩單機個人角色扮演與模擬遊戲。線上遊戲則喜歡玩團隊合作型遊戲。',
                    detailContent: {
                        contentList: [
                            {type: 'textElement', content: '休閒時間，我偶爾會玩電玩來放鬆生活。喜歡遊戲類型較偏好單機角色扮演與模擬遊戲，也曾遊玩線上遊戲，偏好團隊合作型遊戲，享受團隊合作模式完成目標，並傾向在團隊中擔任輔助角色協助隊友。'},
                            {type: 'iFrameVideoElement', description: '線上團隊合作遊戲 (魔獸世界)', content: '<iframe width="560" height="315" src="https://www.youtube.com/embed/QULmjhfdbWo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'},
                        ]
                    },
                }
            ]
        },
        en: {
            myPersonalityInfo: [
                { image: 'interest/finder.jpg', title: 'Actively seek solutions', content: 'Solution Finder: likes to seek improved techniques or tools to make operations more convenient.', 
                detailContent: { 
                    contentList: [ 
                        {type: 'imageElement', description: 'To improve work efficiency, independently develop human cost software planning, quickly complete work instead of using Excel to complete time-consuming work estimation', content: 'project/detail/department/before_after.jpg'}, 
                        {type: 'imageElement', description: 'Use Home Assistant to integrate home appliances, no need to check the phone to notify the operation status of home appliances', content: 'interest/detail/smarthome/homeassistant_structure.png'},
                        ]
                    }   
                },
                { image: 'interest/share.jpg', title: 'Sharing and Helping', content: 'Helper: Willing to serve the organization, others, help and share what they can and do.', 
                    detailContent: { 
                        contentList: [ 
                            {type: 'textElement', content: 'I am happy to share and serve the team. For example, during my service at ITRI, I took the initiative to set up a NAS system for the department team to use and was responsible for subsequent maintenance and updates; I also took the initiative to set up a SVN, Gitlab platform for the team to use and set up a Bug Tracker platform for members and customers to report system bugs. In addition, I also took the initiative to organize departmental gatherings and year-end parties for team service cohesion. In my spare time, I also participate in beach cleaning activities organized by environmental groups and animal-friendly activities.'}, 
                            {type: 'imageElement', description: 'Take initiative to serve department, set up software platform and purchase software', content: 'interest/detail/service/service.png'}, 
                            {type: 'imageElement', description: 'Take initiative to participate in beach cleaning activities', content: 'interest/detail/service/clean.jpg'}, 
                            {type: 'imageElement', description: 'Animal-friendly activities', content: 'interest/detail/service/dog.jpg'},
                        ]
                    }
                },
                { image: 'interest/newthing.jpg', title: 'Accept new things', content: 'Early Adopter: I like to try new things and enjoy new technologies and products.', 
                    detailContent: { 
                        contentList: [ 
                            {type: 'textElement', content: 'I like to try new products and am very willing to buy and use them when the first generation products are released. I also use them when new versions of software are released or new operating systems are released. In addition, I also often fund new products on crowdfunding platforms, such as RhinoShield phone case, mobile watch charger box, desktop air purifier, homemade video games by Taiwanese people, public welfare advertising…etc.'}, 
                        ] 
                    },
                },
            ],
            myInterestInfo: [
                {
                    "image": "interest/room.jpg",
                    "title": "Aesthetic Design",
                    "content": "Explore design aesthetics: graphic design and interior decoration.",
                    "detailContent": {
                        "contentList": [
                            {"type": "textElement", "content": "In my leisure time, I like to decorate my living environment through soft furnishings such as decorations, lighting, and smart home appliances to improve the quality of life. I also design gift packaging to add a sense of ritual to life." },
                            {"type": "imageElement","description": "Interior Decoration","content": "interest/detail/design/interial.jpg"},
                            {"type": "imageElement","description": "Gift Packaging","content": "interest/detail/design/gift.jpg"}
                        ]
                    }
                },
                {
                    "image": "interest/smarthome.jpg",
                    "title": "Smart Home Appliances",
                    "content": "Research on smart homes: native smart home appliances + cross-brand integration.",
                    "detailContent": {
                        "contentList": [
                            {
                                "type": "textElement",
                                "content": "In my daily life, I like to apply my expertise to life. For example, in the field of smart homes, I hope to be able to monitor and operate networked home appliances on a single mobile platform. However, not all home appliances support Apple Homekit on the Apple phone I use. Therefore, I often research how to connect non-Homekit home appliances to Homekit. Here are two examples."
                            },
                            {
                                "type": "imageElement",
                                "description": "Home Assistant Integration Architecture",
                                "content": "interest/detail/smarthome/homeassistant_structure.png"
                            },
                            {
                                "type": "textElement",
                                "content": "The first example is that I use Home Assistant to connect non-Homekit LG washing machines and Xiaomi home appliances through packages developed by experts on Github and the Server API of the original factory of home appliances. Through the Airplay package and Homekit package of Home Assistant, non-Homekit home appliances can be connected to Homekit for convenient life applications. For example, when the washing machine is finished, it will be notified through Homepod that the washing machine is finished, so there is no need to open the APP or pick up the phone to check."
                            },
                            {
                                "type": "iFrameVideoElement",
                                "description": "After the non-Homekit LG washing machine is completed, it is played through Airplay to the completion sound effect in Homepod in Homekit.",
                                "content": "<iframe width=\"410\" height=\"729\" src=\"https://www.youtube.com/embed/KhopxdVDxfk\" title=\"The washing machine is done\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" allowfullscreen></iframe>"
                            },
                            {
                                "type": "iFrameVideoElement",
                                "description": "After the non-Homekit LG dryer is completed, it is played through Airplay to the completion sound effect in Homepod in Homekit.",
                                "content": "<iframe width=\"410\" height=\"729\" src=\"https://www.youtube.com/embed/__Iy13QvYj4\" title=\"The dryer is done\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" allowfullscreen></iframe>"
                            },
                            {
                                "type": 'iFrameVideoElement',
                                'description': 'Integrating Google TTS playback sound (but too unnatural so not adopted)',
                                'content': '<iframe width="410" height="729" src="https://www.youtube.com/embed/BQZ-uI7JUaE" title="TTS sounds weird" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'
                            }
                        ]
                    }
                },
                {
                    "image": "interest/workout.jpg",
                    "title": "Exercise and Fitness",
                    "content": "Running and fitness: maintain a habit of participating in a road race once a year and exercising four times a week.",
                    "detailContent": {
                        "contentList": [
                            {
                                "type": "textElement",
                                "content": "Normally, I like dynamic activities such as running and fitness, and maintain the habit of participating in a road race once a year and exercising regularly four times a week. To develop good exercise habits, I also set up my own Home Gym at home to bring exercise into my life and control my diet with discipline. I record the proportion of carbohydrates, protein, and fat in my diet every day."
                            },
                            {
                                "type": 'iFrameVideoElement',
                                'description': 'Weight training coach class',
                                'content': '<iframe width="410" height="729" src="https://www.youtube.com/embed/_1DRPPZ_vrc" title="Weight training coach class_1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'
                            },
                            {
                                "type": 'iFrameVideoElement',
                                'description': 'Weight training coach class',
                                'content': '<iframe width="410" height="729" src="https://www.youtube.com/embed/2AKjGIHlPw0" title="Weight training coach class_2" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'
                            },
                            {
                                "type": 'imageElement',
                                'description': 'Running',
                                'content': 'interest/detail/sport/run.jpg'
                            },
                            {
                                "type": 'imageElement',
                                'description': 'Home Gym',
                                'content': 'interest/detail/sport/homegym1.jpg'
                            },
                            {
                                "type": 'imageElement',
                                'description': 'Home Gym Accessories',
                                'content': 'interest/detail/sport/homegym2.jpg'
                            }
                        ]
                    }
                },
                {
                    "image": "interest/game.jpg",
                    "title": "Video Games",
                    "content": "I like to play single-player role-playing and simulation games. For online games, I prefer team-based cooperative games.",
                    "detailContent": {
                        "contentList": [
                            {
                                "type": "textElement",
                                "content": "In my leisure time, I occasionally play video games to relax. I prefer single-player role-playing and simulation games. I have also played online games and prefer team-based cooperative games. I enjoy working together with a team to achieve goals and tend to take on a supporting role to assist my teammates."
                            },
                            {
                                'type': 'iFrameVideoElement',
                                'description': 'Online team-based cooperative game (World of Warcraft)',
                                'content': '<iframe width="560" height="315" src="https://www.youtube.com/embed/QULmjhfdbWo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'
                            }
                        ]
                    }
                }
            ]
        }
      }
    }
}